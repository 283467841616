// App.js

import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  Box,
  Button,
  Select,
  MenuItem,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Alert,
  Typography,
  FormControl,
  InputLabel,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import moment from 'moment';
import ListView from './ListView';
import useSupercluster from 'use-supercluster';
import ClusterCarousel from './ClusterCarousel';
import CarouselPopup from './CarouselPopup';
import CustomMarker from './CustomMarker';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiY3JhaWdidXJnb3luZSIsImEiOiJjbHllaTM2em8wMndqMm1vcWZoYmZnaG9rIn0.hHWwDWHl2wqegwpT3lIgWg';
const BACKEND_URL = 'https://gathr-backend-951249927221.northamerica-northeast1.run.app';



// Custom Specials Icon component for the bottom navigation
const SpecialsIcon = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <RestaurantIcon sx={{ fontSize: 20, mr: 0, ml: 0.4 }} />
    <SportsBarIcon sx={{ fontSize: 20, ml: 0, mr: 0.2 }} />
  </Box>
);

// Utility function to format dates
function formatDate(date) {
  return moment(date).format('MMM D');
}

// Utility function to format times
function formatTime(timeString) {
  return moment(timeString, ['h:mm A', 'H:mm:ss'])
    .format('h:mm A')
    .toLowerCase();
}

// Determines the status of a marker based on current time
function getMarkerStatus(marker) {
  const now = moment();
  const { startDate, endDate, startTime, endTime } = marker;

  const start = moment(`${startDate}T${startTime}`, [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
  ]);
  const end = moment(`${endDate}T${endTime}`, [
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
    'YYYY-MM-DDTh:mm A',
  ]);

  const inAWeek = moment().add(7, 'days').endOf('day');   //change the 7 value to be as many days into the future as you want to display. 

  if (now.isBetween(start, end)) {
    return 'green'; // Happening now
  }

  if (start.isSameOrBefore(now, 'day') && end.isAfter(now, 'day')) {
    return 'yellow'; // Happening today
  }

  if (start.isAfter(now) && start.isBefore(inAWeek)) {
    return 'grey'; // Upcoming in the next 7 days
  }

  return 'expired'; // Event has ended or is more than 7 days away
}


function App() {
  // Map view state
  const [viewState, setViewState] = useState({
    latitude: 46.2352,
    longitude: -63.1262,
    zoom: 12,
  });

  const [currentView, setCurrentView] = useState('map'); // Current view (map, events, specials)
  const [markers, setMarkers] = useState([]); // All markers from the backend
  const [selectedLocation, setSelectedLocation] = useState(null); // Selected location for popup
  const [view, setView] = useState('map'); // View for bottom navigation
  const [selectedCluster, setSelectedCluster] = useState(null); // Selected cluster for carousel
  const [bounds, setBounds] = useState(null); // Map bounds for clustering
  const [isMapAlertVisible, setMapAlertVisible] = useState(true); // Visibility of map alert
  const [userLocation, setUserLocation] = useState(null); // User's current location

  // Filter states
  const [eventsSelected, setEventsSelected] = useState(true); // Toggle for Events
  const [specialsSelected, setSpecialsSelected] = useState(true); // Toggle for Specials

  // Separate time filters for Events and Specials
  const [eventsTimeFilter, setEventsTimeFilter] = useState('');
  const [specialsTimeFilter, setSpecialsTimeFilter] = useState('');

  // Separate category filters for Events and Specials
  const [eventsCategoryFilter, setEventsCategoryFilter] = useState('');
  const [specialsCategoryFilter, setSpecialsCategoryFilter] = useState('');

  // Separate categories for Events and Specials derived from markers
  const [eventsCategories, specialsCategories] = useMemo(() => {
    const eventsCategoriesSet = new Set();
    const specialsCategoriesSet = new Set();
    markers.forEach((marker) => {
      if (marker.category) {
        if (marker.type.toLowerCase() === 'event') {
          eventsCategoriesSet.add(marker.category);
        } else if (marker.type.toLowerCase() === 'special') {
          specialsCategoriesSet.add(marker.category);
        }
      }
    });
    return [Array.from(eventsCategoriesSet), Array.from(specialsCategoriesSet)];
  }, [markers]);

  // Filter bar state
  const [filterBarOpen, setFilterBarOpen] = useState(false); // Toggle for filter bar visibility
  const filterBarRef = useRef(null); // Reference to the filter bar for ClickAwayListener

  // Use theme and media queries for responsive design
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle back navigation from non-map views
  const handleBack = () => {
    if (view !== 'map') {
      setView('map');
    }
  };

  // Adjust body overflow based on current view
  useLayoutEffect(() => {
    if (currentView === 'map') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [currentView]);

  // Fetch markers from backend on component mount
  useEffect(() => {
    const fetchMarkers = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/events`);
        const data = await response.json();
        const eventsWithStatus = data.map((event) => ({
          ...event,
          status: getMarkerStatus(event),
        }));
        setMarkers(eventsWithStatus);
      } catch (error) {
        console.error('Error fetching markers:', error);
      }
    };

    fetchMarkers();
  }, []);

  // consts for following user toggles start
  const [shouldFollowUser, setShouldFollowUser] = useState(false);
  const [isInitialLocation, setIsInitialLocation] = useState(true);
  //follow user toggles ends
  
  // Get user's current location and fllow them
  
  useEffect(() => {
    let watchId;
  
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const newUserLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setUserLocation(newUserLocation);
  
          // Center the map on the user's location when it's first obtained
          if (isInitialLocation) {
            setViewState((prevState) => ({
              ...prevState,
              latitude: newUserLocation.latitude,
              longitude: newUserLocation.longitude,
              zoom: 12, // You can adjust this zoom level as needed
            }));
            setIsInitialLocation(false);
          } else if (shouldFollowUser) {
            // Update the map view only if shouldFollowUser is true
            setViewState((prevState) => ({
              ...prevState,
              latitude: newUserLocation.latitude,
              longitude: newUserLocation.longitude,
              // Keep the current zoom level
              zoom: prevState.zoom,
            }));
          }
        },
        (error) => {
          console.error('Error getting user location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 1000,
          distanceFilter: 5,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [shouldFollowUser, isInitialLocation]);
  
  // Add this function to handle manual recentering
  const handleRecenterMap = () => {
    if (userLocation) {
      setViewState((prevState) => ({
        ...prevState,
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
        zoom: 12, // You can adjust this zoom level
        transitionDuration: 1000, // Add smooth transition
      }));
    }
  };



  // Hide map alert after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMapAlertVisible(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Compute filtered markers based on filters
// Compute filtered markers based on filters
  const filteredMarkers = useMemo(() => {
    return markers.filter((marker) => {
      const isEvent = marker.type.toLowerCase() === 'event';
      const isSpecial = marker.type.toLowerCase() === 'special';

      // Filter by Events and Specials toggle
      if (!eventsSelected && isEvent) return false;
      if (!specialsSelected && isSpecial) return false;

      const markerStatus = getMarkerStatus(marker);

      // Exclude events that have ended or are more than 7 days in the future
      if (isEvent && ['green', 'yellow', 'grey'].includes(markerStatus) === false) {
        return false;
      }

      // Apply separate time filters for Events
      if (isEvent && eventsTimeFilter) {
        if (eventsTimeFilter === 'Now' && markerStatus !== 'green') return false;
        if (eventsTimeFilter === 'Today' && !['green', 'yellow'].includes(markerStatus)) {
          return false;
        }
        if (eventsTimeFilter === 'Upcoming' && markerStatus !== 'grey') return false;
      }

      // Apply separate time filters for Specials
      if (isSpecial && specialsTimeFilter) {
        if (specialsTimeFilter === 'Now' && markerStatus !== 'green') return false;
        if (specialsTimeFilter === 'Today' && !['green', 'yellow'].includes(markerStatus)) {
          return false;
        }
        if (specialsTimeFilter === 'Upcoming' && markerStatus !== 'grey') return false;
      }

      // Apply separate category filters for Events
      if (isEvent && eventsCategoryFilter && marker.category !== eventsCategoryFilter) {
        return false;
      }

      // Apply separate category filters for Specials
      if (isSpecial && specialsCategoryFilter && marker.category !== specialsCategoryFilter) {
        return false;
      }

      return true;
    });
  }, [
    markers,
    eventsSelected,
    specialsSelected,
    eventsTimeFilter,
    specialsTimeFilter,
    eventsCategoryFilter,
    specialsCategoryFilter,
  ]);


  // Prepare points for clustering
  const points = useMemo(() => {
    const getStatusPriority = (status) => {
      switch (status) {
        case 'green':
          return 2;
        case 'yellow':
          return 1;
        case 'grey':
          return 0;
        default:
          return -1;
      }
    };

    const groupedMarkers = filteredMarkers.reduce((acc, marker) => {
      const key = `${marker.latitude},${marker.longitude}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(marker);
      return acc;
    }, {});

    return Object.entries(groupedMarkers).map(([key, locationMarkers]) => {
      const highestPriorityMarker = locationMarkers.reduce((highest, current) => {
        return getStatusPriority(current.status) > getStatusPriority(highest.status)
          ? current
          : highest;
      });

      return {
        type: 'Feature',
        properties: {
          cluster: false,
          markerId: highestPriorityMarker.id,
          category: highestPriorityMarker.category,
          type: highestPriorityMarker.type,
          status: highestPriorityMarker.status,
          eventCount: locationMarkers.length,
          markers: locationMarkers,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(highestPriorityMarker.longitude),
            parseFloat(highestPriorityMarker.latitude),
          ],
        },
      };
    });
  }, [filteredMarkers]);

  // Use Supercluster for clustering markers
  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewState.zoom,
    bounds: bounds,
    options: {
      radius: 75,
      maxZoom: 20,
      minPoints: 2,
    },
  });

  // Handle map load to set initial bounds
  const handleMapLoad = useCallback((event) => {
    const map = event.target;
    const updateBounds = () => {
      const b = map.getBounds();
      setBounds([b.getWest(), b.getSouth(), b.getEast(), b.getNorth()]);
    };
    map.on('moveend', updateBounds);
    updateBounds();
  }, []);

  // Handle cluster click to zoom into clusters
  const handleClusterClick = useCallback(
    (clusterId, longitude, latitude) => {
      const children = supercluster.getLeaves(clusterId, Infinity);

      if (children.length === 1 && children[0].properties.eventCount > 1) {
        setSelectedLocation(children[0].properties.markers);
        setSelectedCluster(null);
      } else {
        setSelectedCluster(children.map((child) => child.properties.markers).flat());
        setSelectedLocation(null);
      }

      setViewState({
        ...viewState,
        longitude,
        latitude,
        transitionDuration: 500,
      });
    },
    [supercluster, setViewState, viewState]
  );

  // Handle toggling Events
  const handleEventsToggle = () => {
    setEventsSelected(!eventsSelected);
  };

  // Handle toggling Specials
  const handleSpecialsToggle = () => {
    setSpecialsSelected(!specialsSelected);
  };

  // Handle clearing all filters
  const handleClearFilters = () => {
    setEventsSelected(true);
    setSpecialsSelected(true);
    setEventsTimeFilter('');
    setSpecialsTimeFilter('');
    setEventsCategoryFilter('');
    setSpecialsCategoryFilter('');
  };

  // Handle filter bar toggle
  const handleFilterToggle = () => {
    setFilterBarOpen(!filterBarOpen);
  };

  // Handle click away from filter bar to close it
  const handleClickAway = (event) => {
    if (filterBarRef.current && !filterBarRef.current.contains(event.target)) {
      setFilterBarOpen(false);
    }
  };

  // Handle event click
  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    // You can add more logic here, such as navigating to an event detail page
  };

  // Main render function
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* App Bar at the top */}
      <AppBar position="fixed" sx={{ top: 0, zIndex: 1200 }}>
        <Toolbar>
          {/* Back button */}
          <Box sx={{ width: 48, display: 'flex', justifyContent: 'center' }}>
            {view !== 'map' && (
              <IconButton edge="start" color="inherit" onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
          </Box>
          {/* Centered logo */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/logo192.webp`}
              alt="GathR Logo"
              style={{ height: 40 }}
            />
          </Box>
          {/* Settings button */}
          <Box sx={{ width: 48, display: 'flex', justifyContent: 'center' }}>
            <IconButton color="inherit" aria-label="settings">
              <SettingsIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main content area */}
      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: `calc(64px + env(safe-area-inset-bottom, 64px))`,
          minHeight: 0,
        }}
      >
        {/* Map view */}
        {view === 'map' && (
          <>
            {/* Map component */}
            <Map
              {...viewState}
              onMove={(evt) => setViewState(evt.viewState)}
              style={{ width: '100%', height: '100%' }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxAccessToken={MAPBOX_TOKEN}
              onClick={() => {
                setSelectedLocation(null);
                setSelectedCluster(null);
              }}
              onLoad={handleMapLoad}
            >
                                                          {/* User's location marker */}
              {/* Inside the Map component */}
              {userLocation && (
                <Marker
                  latitude={userLocation.latitude}
                  longitude={userLocation.longitude}
                  anchor="center" // centers the marker on users location. can also be set to bottom
                >
                  <div style={{
                    position: 'relative',
                    width: '20px',
                    height: '20px'
                  }}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="#E53935"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 0C7.31 0 3.5 3.81 3.5 8.5C3.5 14.88 12 24 12 24C12 24 20.5 14.88 20.5 8.5C20.5 3.81 16.69 0 12 0ZM12 11.5C10.34 11.5 9 10.16 9 8.5C9 6.84 10.34 5.5 12 5.5C13.66 5.5 15 6.84 15 8.5C15 10.16 13.66 11.5 12 11.5Z"/>
                    </svg>
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      animation: 'pulse 2s infinite'
                    }}></div>
                    <style jsx>{`
                      @keyframes pulse {
                        0% {
                          box-shadow: 0 0 0 0 rgba(229, 57, 53, 0.4);
                        }
                        70% {
                          box-shadow: 0 0 0 15px rgba(229, 57, 53, 0);
                        }
                        100% {
                          box-shadow: 0 0 0 0 rgba(229, 57, 53, 0);
                        }
                      }
                    `}</style>
                  </div>
                </Marker>
              )}

              {/* Render clusters and markers */}
              {clusters.map((cluster) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                if (isCluster) {
                  // Render cluster marker
                  return (
                    <Marker key={`cluster-${cluster.id}`} latitude={latitude} longitude={longitude}>
                      <div
                        style={{
                          width: `${20 + (pointCount / points.length) * 20}px`,
                          height: `${20 + (pointCount / points.length) * 20}px`,
                          borderRadius: '50%',
                          backgroundColor: '#1976d2',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: 12,
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClusterClick(cluster.id, longitude, latitude);
                        }}
                      >
                        {pointCount}
                      </div>
                    </Marker>
                  );
                }

                // Render individual marker
                const marker = cluster.properties;
                return (
                  <Marker
                    key={`marker-${marker.markerId}`}
                    latitude={latitude}
                    longitude={longitude}
                    anchor="bottom"
                    onClick={(e) => {
                      e.originalEvent.stopPropagation();
                      setSelectedLocation(marker.markers);
                      setSelectedCluster(null);
                      setViewState((prevState) => ({
                        ...prevState,
                        longitude: longitude,
                        latitude: latitude + 0.0002,
                        transitionDuration: 500,
                      }));
                    }}
                  >
                    <CustomMarker
                      type={marker.type}
                      status={marker.status}
                      count={marker.eventCount > 1 ? marker.eventCount : undefined}
                    />
                  </Marker>
                );
              })}

              {/* Carousel popup for selected location */}
              {selectedLocation && (
                <CarouselPopup
                  markers={selectedLocation}
                  onClose={() => setSelectedLocation(null)}
                />
              )}

              {/* Carousel for selected cluster */}
              {selectedCluster && (
                <ClusterCarousel
                  events={selectedCluster}
                  onClose={() => setSelectedCluster(null)}
                />
              )}
            </Map>

            {/* Filter Bar */}
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 70,
                  left: 10,
                  right: 10,
                  zIndex: 1000,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {!filterBarOpen && (
                  <Button
                    variant="contained"
                    onClick={handleFilterToggle}
                    sx={{
                      backgroundColor: '#E9EBEC', // FILTER Button background color
                      color: '#8B0000', // FILTER BUTTON Button text color
                      border: '2px solid #4f4f4f', //boreder around the filters button.
                      borderRadius: '8px', // Optional: keep or adjust as per your preference
                      borderWidth: 2, // BORDER WITDTH WHEN NOT SELECTED. 
                      fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                      padding: isSmallScreen ? '2px 6px' : '4px 8px',
                      minWidth: '50px',
                      '&:hover': {
                        backgroundColor: 'rgba(213, 213, 213, 0.7)', //  hover OVER FILTER BUTTON background color
                      },
                    }}
                  >     {/* END OF FILTERBAR */}
                    Filters
                  </Button>
                )}                    {/* START OF BOX THAT HOLDS THE TOP ROW OF FILTERS */}
                {filterBarOpen && (
                  <Box
                    ref={filterBarRef}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto 1fr',
                      gridTemplateRows: 'auto auto',
                      gap: '5px',
                      alignItems: 'center',
                      justifyItems: 'center',
                      backgroundColor: 'transparent',
                      //backdropFilter: 'blur(8px)',
                      borderRadius: '8px',
                      padding: '5px',
                    }}       //* END OF BOX THAT HOLDS THE TOP ROW OF FILTERS */
                  >
                    {/* Top Row: Buttons */}
                    {/* Start of Event Button */}
                    <Button
                      variant={eventsSelected ? 'contained' : 'outlined'}  
                      onClick={handleEventsToggle}
                      sx={{
                        gridColumn: 1,
                        backgroundColor: eventsSelected
                          ? '#1976d2'  // Change this to match your AppBar color
                          : '#cfcfcf',
                        color: '#fff', // Events button text color
                        borderColor: '#1976d2',  // Change this to match your AppBar color
                        borderWidth: 2, // BORDER WITDTH WHEN NOT SELECTED. 
                        fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                        padding: isSmallScreen ? '2px 6px' : '4px 8px',
                        minWidth: '50px',
                        '&:hover': {
                          backgroundColor: eventsSelected
                            ? '#1565c0'  // grey when unselected
                            : '#1976d2',  // blue while hovering
                            borderWidth: 2, // BORDER WIDTH WHEN HOVERING
                        },    
                      }}
                    >
                      Events
                    </Button>
                     {/* End of Event Button */}

                       {/* Start of Clear Button */}
                    <Button
                      variant="outlined"
                      onClick={handleClearFilters}
                      sx={{
                        gridColumn: 2,
                        color: '#8B0000',  // Change this to match your AppBar color
                        border: '2px solid #4f4f4f', //boreder around the filters button.
                        borderRadius: '8px', // Optional: keep or adjust as per your preference
                        borderColor: '#4f4f4f',  // Change this to match your AppBar color
                        borderWidth: 2,
                        backgroundColor: '#E9EBEC',
                        fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                        padding: isSmallScreen ? '2px 6px' : '4px 8px',
                        minWidth: '50px',
                        ":hover" : {   //control what happens when hoverying
                          backgroundColor: '#8B0000',
                          color: '#fff',
                          border: '2px solid #4f4f4f', //boreder around the filters button.
                          borderRadius: '8px', // Optional: keep or adjust as per your preference
                          borderColor: '#4f4f4f',  // Change this to match your AppBar color
                          borderWidth: 2,
                        }
                      }}
                    >
                      Clear
                    </Button>
                        {/* End of Clear Button */} 

                       {/* Start of Special Button */}
                    <Button
                      variant={specialsSelected ? 'contained' : 'outlined'}
                      onClick={handleSpecialsToggle}
                      sx={{
                        gridColumn: 3,
                        backgroundColor: specialsSelected
                        ? '#1976d2'  // Change this to match your AppBar color
                        : '#cfcfcf',
                        color: '#fff', // Events button text color
                        borderColor: '#1976d2',  // Change this to match your AppBar color
                        borderWidth: 2, // BORDER WITDTH WHEN NOT SELECTED. 
                        fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                        padding: isSmallScreen ? '2px 6px' : '4px 8px',
                        minWidth: '50px',
                        '&:hover': {
                          backgroundColor: specialsSelected
                            ? '#1565c0'  // grey when unselected
                            : '#1976d2',  // blue while hovering
                            borderWidth: 2, // BORDER WIDTH WHEN HOVERING
                        },
                      }}
                    >
                      Specials
                    </Button>

                    {/* Bottom Row: Filters */}
                    {/* Events Filters under Events button */}
                    {eventsSelected && (
                      <Box
                        sx={{
                          gridColumn: 1,
                          display: 'flex',
                          backgroundColor: '#E9EBEC',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                          {/* Events Filters */}
                          {/* Category Filter to the left of Time Filter */}
                          <FormControl
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: 80,
                              marginRight: isSmallScreen ? '4px' : '8px',
                              flexShrink: 1,
                            }}
                          >
                            <InputLabel
                              sx={{
                                fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                                color: '#c65102', // Events Category label color
                              }}
                            >
                              Category
                            </InputLabel>
                            <Select
                              value={eventsCategoryFilter}
                              onChange={(e) => setEventsCategoryFilter(e.target.value)}
                              label="Category"
                              MenuProps={{ disablePortal: true }}
                              sx={{
                                fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                                color: '#c65102', // Events Time text color
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#c65102', // Events Time border color
                                  borderWidth: 2,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#c65102', // Events Time focused border color
                                  borderWidth: 2,
                                },
                                '.MuiSvgIcon-root': {
                                  color: '#c65102', // Events Time icon color
                                  borderWidth: 2,
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>All Categories</em>
                              </MenuItem>
                              {eventsCategories.map((category) => (
                                <MenuItem key={category} value={category}>
                                  {category}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: 80,
                              flexShrink: 1,
                            }}
                          >
                            <InputLabel
                              sx={{
                                fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                                color: '#c65102', // Events Time label color
                              }}
                            >
                              Time
                            </InputLabel>
                            <Select
                              value={eventsTimeFilter}
                              onChange={(e) => setEventsTimeFilter(e.target.value)}
                              label="Time"
                              MenuProps={{ disablePortal: true }}
                              sx={{
                                fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                                color: '#c65102', // Events Time text color
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#c65102', // Events Time border color
                                  borderWidth: 2,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#c65102', // Events Time focused border color
                                  borderWidth: 2,
                                },
                                '.MuiSvgIcon-root': {
                                  color: '#c65102', // Events Time icon color
                                  borderWidth: 2,
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>All Times</em>
                              </MenuItem>
                              <MenuItem value="Now">Happening Now</MenuItem>
                              <MenuItem value="Today">Happening Today</MenuItem>
                              <MenuItem value="Upcoming">Upcoming</MenuItem>
                            </Select>
                          </FormControl>
                      </Box>
                    )}

                    {/* Specials Filters under Specials button */}
                    {specialsSelected && (
                      <Box
                        sx={{
                          gridColumn: 3,
                          display: 'flex',
                          backgroundColor: '#E9EBEC',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {/* Specials Filters */}
                        {/* Time Filter under Specials Button */}
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{
                            minWidth: 80,
                            marginRight: isSmallScreen ? '4px' : '8px',
                            flexShrink: 1,
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                              color: '#c65102', // Specials Time label color
                            }}
                          >
                            Time
                          </InputLabel>
                          <Select
                            value={specialsTimeFilter}
                            onChange={(e) => setSpecialsTimeFilter(e.target.value)}
                            label="Time"
                            MenuProps={{ disablePortal: true }}
                            sx={{
                              fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                              color: '#c65102', // Specials Time text color
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#c65102', // Specials Time border color
                                borderWidth: 2,
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#c65102', // Specials Time focused border color
                                borderWidth: 2,
                              },
                              '.MuiSvgIcon-root': {
                                color: '#c65102', // Specials Time icon color
                                borderWidth: 2,
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>All Times</em>
                            </MenuItem>
                            <MenuItem value="Now">Happening Now</MenuItem>
                            <MenuItem value="Today">Happening Today</MenuItem>
                            <MenuItem value="Upcoming">Upcoming</MenuItem>
                          </Select>
                        </FormControl>

                        {/* Category Filter to the right of Time Filter */}
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{
                            minWidth: 80,
                            flexShrink: 1,
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                              color: '#c65102', // Specials Category label color
                            }}
                          >
                            Category
                          </InputLabel>
                          <Select
                            value={specialsCategoryFilter}
                            onChange={(e) => setSpecialsCategoryFilter(e.target.value)}
                            label="Category"
                            MenuProps={{ disablePortal: true }}
                            sx={{
                              fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                              color: '#c65102', // Specials Category text color
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#c65102', // Specials Category border color
                                borderWidth: 2,
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#c65102', // Specials Category focused border color
                                borderWidth: 2,
                              },
                              '.MuiSvgIcon-root': {
                                color: '#c65102', // Specials Category icon color
                                borderWidth: 2,
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>All Categories</em>
                            </MenuItem>
                            {specialsCategories.map((category) => (
                              <MenuItem key={category} value={category}>
                                {category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
          </>
        )}
     
            {/* Toggle Follow User Button with Description */}
            {/* start of center on user icons / buttons */}
            {view === 'map' && ( 
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 240, // Adjust this value as needed
                  right: 5, // Adjust this value as needed
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: 2, // Reduced space between buttons
                }}
              >
                {/* Stay Centered Button */}
                <Box
                  sx={{
                    width: 90, // Reduced width for both button containers
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="caption" sx={{ mb: 0.25, backgroundColor: '#E9EBEC', px: 0.5, borderRadius: 1, width: '100%', textAlign: 'center', fontSize: '0.6rem' }}>
                    {shouldFollowUser ? 'Centered' : 'Stay Centered'}
                  </Typography>
                  <IconButton
                    onClick={() => setShouldFollowUser(!shouldFollowUser)}
                    sx={{
                      backgroundColor: shouldFollowUser ? 'primary.main' : 'background.paper',
                      color: shouldFollowUser ? 'background.paper' : 'text.primary',
                      '&:hover': {
                        backgroundColor: shouldFollowUser ? 'primary.dark' : 'background.default',
                      },
                      padding: '4px', // Reduce padding to make button smaller
                    }}
                  >
                    <MyLocationIcon sx={{ fontSize: 16 }} /> {/* Reduce icon size */}
                  </IconButton>
                </Box>

                {/* Recenter Button */}
                <Box
                  sx={{
                    width: 90, // Same reduced width as above
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="caption" sx={{ mb: 0.25, backgroundColor: '#E9EBEC', px: 0.5, borderRadius: 1, width: '100%', textAlign: 'center', fontSize: '0.6rem' }}>
                    Recenter
                  </Typography>
                  <IconButton
                    onClick={handleRecenterMap}
                    sx={{
                      backgroundColor: 'background.paper',
                      color: 'text.primary',
                      '&:hover': {
                        backgroundColor: 'background.default',
                      },
                      padding: '4px', // Reduce padding to make button smaller
                    }}
                  >
                    <GpsFixedIcon sx={{ fontSize: 16 }} /> {/* Reduce icon size */}
                  </IconButton>
                </Box>
              </Box>
            )}
                                    {/* End of centering icons */}
          
       
        {/* ListView for Events or Specials */}
        {(view === 'events' || view === 'specials') && (
          <ListView
            events={filteredMarkers.filter((marker) =>
              view === 'events'
                ? marker.type.toLowerCase() === 'event'
                : marker.type.toLowerCase() === 'special'
            )}
            onEventClick={handleEventClick}
            viewType={view}
          />
        )}

        {/* Map alert for user's location */}
        {isMapAlertVisible && (
          <Box sx={{ position: 'absolute', top: 16, left: 16, zIndex: 2000 }}>
            <Alert severity="info">Map centered on user's location</Alert>
          </Box>
        )}
      </Box>

      {/* Bottom Navigation */}
      <BottomNavigation
        value={view}
        onChange={(event, newValue) => setView(newValue)}
        showLabels
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
          borderTop: '1px solid #ddd',
          zIndex: 1200,
        }}
      >
        <BottomNavigationAction label="Events" icon={<EventIcon />} value="events" />
        <BottomNavigationAction label="Map" icon={<LocationOnIcon />} value="map" />
        <BottomNavigationAction label="Specials" icon={<SpecialsIcon />} value="specials" />
      </BottomNavigation>
    </Box>
  );
}

export default App;
