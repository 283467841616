import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CarouselPopup from './CarouselPopup';
import moment from 'moment';

const ClusterCarousel = ({ events, onClose, navBarHeight }) => {
  const [groupedEvents, setGroupedEvents] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    // Group events by location
    const groupedByLocation = events.reduce((acc, event) => {
      const key = `${event.latitude},${event.longitude}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(event);
      return acc;
    }, {});

    // Helper function to determine status priority
    const getStatusPriority = (status) => {
      switch (status) {
        case 'green': return 2;
        case 'yellow': return 1;
        case 'grey': return 0;
        default: return -1;
      }
    };

    // Sort location groups
    const sortedGroups = Object.values(groupedByLocation).sort((a, b) => {
      const aHighestPriority = Math.max(...a.map(event => getStatusPriority(event.status)));
      const bHighestPriority = Math.max(...b.map(event => getStatusPriority(event.status)));
      
      if (aHighestPriority !== bHighestPriority) {
        return bHighestPriority - aHighestPriority;
      }
      
      // If both locations have only grey events, sort by earliest event date
      if (aHighestPriority === 0 && bHighestPriority === 0) {
        const aEarliestDate = moment(a.reduce((min, event) => moment(event.startDate).isBefore(min) ? event.startDate : min, a[0].startDate));
        const bEarliestDate = moment(b.reduce((min, event) => moment(event.startDate).isBefore(min) ? event.startDate : min, b[0].startDate));
        return aEarliestDate.diff(bEarliestDate);
      }
      
      return 0;
    });

    setGroupedEvents(sortedGroups);
  }, [events]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.cluster-carousel')) {
        onClose();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  const handleGroupChange = (direction) => {
    setCurrentGroupIndex(prev => {
      const newIndex = direction === 'next'
        ? (prev + 1) % groupedEvents.length
        : (prev - 1 + groupedEvents.length) % groupedEvents.length;
      setCurrentEventIndex(0);  // Reset to first event in new group
      return newIndex;
    });
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleGroupChange('next');
    } else if (isRightSwipe) {
      handleGroupChange('prev');
    }
  };

  if (groupedEvents.length === 0) return null;

  const currentGroup = groupedEvents[currentGroupIndex] || [];
  const prevGroup = groupedEvents[(currentGroupIndex - 1 + groupedEvents.length) % groupedEvents.length];
  const nextGroup = groupedEvents[(currentGroupIndex + 1) % groupedEvents.length];

  return (
    <Box
      className="cluster-carousel"
      sx={{
        position: 'absolute',
        bottom: 90,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '95%',
        maxWidth: 600,
        height: 'auto',
        maxHeight: `calc(100% - ${navBarHeight - 0}px)`,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        borderTopRightRadius: 2,
        borderTopLeftRadius: 2,
        overflow: 'hidden',
        zIndex: 1000,
        bgcolor: 'transparent',
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        maxHeight: 450,
        width: '100%',
      }}>
        {/* Previous Group Preview */}
        {prevGroup && (
          <Box
            sx={{
              width: 'flex',
              height: '100%',
              opacity: 0.5,
              cursor: 'pointer',
              display: 'block',
              overflow: 'hidden',
              marginRight: '0px',
              zIndex: 1000,
              flexShrink: 1,
            }}
            onClick={() => handleGroupChange('prev')}
          >
            <CarouselPopup
              markers={prevGroup}
              isClusterView={true}
              width="100%"
              height="100%"
            />
          </Box>
        )}
        
        {/* Current Group (Main Popup) */}
        <Box sx={{ 
          flex: 1,
          height: '100%',
          textAlign: 'center',
          marginLeft: '10px',
          marginRight: '10px',
        }}>
          <CarouselPopup
            markers={currentGroup}
            isClusterView={true}
            width="100%"
            height="100%"
            currentIndex={currentEventIndex}
            onIndexChange={setCurrentEventIndex}
          />
        </Box>
        
        {/* Next Group Preview */}
        {nextGroup && (
          <Box
            sx={{
              width: 'flex',
              height: '100%',
              opacity: 0.5,
              cursor: 'pointer',
              display: 'block',
              overflow: 'hidden',
              marginLeft: '0px',
              zIndex: 1000,
              flexShrink: 1,
            }}
            onClick={() => handleGroupChange('next')}
          >
            <CarouselPopup
              markers={nextGroup}
              isClusterView={true}
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </Box>

      <Box 
        className="location-bar"
        sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          height: '24px',
          bgcolor: 'rgba(0, 0, 0, 0.7)',
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2,
        }}
      >
        <Typography 
          variant="caption" 
          color="white" 
          fontWeight="bold" 
          sx={{
            fontSize: '0.7rem',
            lineHeight: 1,
            textAlign: 'center',
            width: '100%',
            padding: 0,
            margin: 0,
          }}
        >
          Location {currentGroupIndex + 1} of {groupedEvents.length}
        </Typography>
      </Box>
    </Box>
  );
};

export default ClusterCarousel;