import React, { useState, useMemo, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Chip, 
  IconButton,
  Paper,
  styled,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment';

// Styled components (unchanged)
const EventCard = styled(Paper)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  marginBottom: theme.spacing(3),
}));

const EventImage = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
});

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

// Updated AdComponent

const AdComponent = ({ isDisabled = false }) => {
  const [adError, setAdError] = useState(null);
  const [showFallback, setShowFallback] = useState(false);
  const adRef = useRef(null);
  const initialized = useRef(false);
  const attemptedInitialization = useRef(false);

  useEffect(() => {
    if (isDisabled || initialized.current || attemptedInitialization.current) {
      return;
    }

    console.log("AdComponent mounted");
    attemptedInitialization.current = true;

    const loadAdScript = () => {
      return new Promise((resolve, reject) => {
        if (window.adsbygoogle) {
          console.log("AdSense script already loaded");
          resolve();
          return;
        }

        const adScript = document.createElement("script");
        adScript.async = true;
        adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9606287073864764";
        adScript.crossOrigin = "anonymous";
        adScript.onload = () => {
          console.log("AdSense script loaded successfully");
          resolve();
        };
        adScript.onerror = (error) => {
          console.error("AdSense script failed to load:", error);
          reject(error);
        };
        document.head.appendChild(adScript);
      });
    };

    const initializeAd = async () => {
      try {
        await loadAdScript();

        if (typeof window.adsbygoogle === 'undefined') {
          throw new Error("AdSense object not available after script load");
        }

        if (adRef.current && !adRef.current.hasAttribute('data-adsbygoogle-initialized')) {
          console.log("Attempting to initialize ad");
          window.adsbygoogle.push({});
          initialized.current = true;
          console.log("Ad push called");

          const timeout = setTimeout(() => {
            console.log("Ad load timeout, showing fallback");
            setShowFallback(true);
          }, 10000);

          const checkAdLoaded = setInterval(() => {
            if (adRef.current && adRef.current.querySelector('iframe')) {
              console.log("Ad iframe detected, clearing timeout");
              clearTimeout(timeout);
              clearInterval(checkAdLoaded);
            }
          }, 1000);

          return () => {
            clearTimeout(timeout);
            clearInterval(checkAdLoaded);
          };
        } else {
          console.log("Ad already initialized or ref not available");
        }
      } catch (e) {
        console.error('AdSense error:', e);
        setAdError(e.message);
      }
    };

    initializeAd();
  }, [isDisabled]);

  if (isDisabled) {
    return null;
  }

  return (
    <div style={{ minHeight: '100px', margin: '10px 0' }}>
      {adError ? (
        <div style={{ color: 'red', textAlign: 'center' }}>
          Ad failed to load: {adError}
        </div>
      ) : showFallback ? (
        <img 
          src="https://via.placeholder.com/300x100?text=Fallback+Ad" 
          alt="Fallback Ad" 
          style={{ width: '100%', height: '100px', objectFit: 'cover' }}
        />
      ) : (
        <ins
          ref={adRef}
          className="adsbygoogle"
          style={{
            display: 'block',
            border: '1px solid #ccc',
            minHeight: '100px',
            backgroundColor: '#f0f0f0',
          }}
          data-ad-client="ca-pub-9606287073864764"
          data-ad-slot="1902171465"
          data-ad-format="fluid"
          data-ad-layout-key="-ef+6k-30-ac+ty"
          data-full-width-responsive="true"
        ></ins>
      )}
    </div>
  );
};


// ImageCarousel component (unchanged)
const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <EventImage src={images[currentImageIndex]} alt="Event" />
      {images.length > 1 && (
        <>
          <IconButton
            onClick={handlePrevImage}
            sx={{ position: 'absolute', left: 8, top: '50%', transform: 'translateY(-50%)', bgcolor: 'rgba(255,255,255,0.7)' }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={handleNextImage}
            sx={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)', bgcolor: 'rgba(255,255,255,0.7)' }}
          >
            <ChevronRight />
          </IconButton>
        </>
      )}
    </Box>
  );
};

const ListView = ({ events, onEventClick, viewType }) => {
  const [venueIndices, setVenueIndices] = useState({});
  const [timeFilter, setTimeFilter] = useState('all');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const categories = useMemo(() => {
    const uniqueCategories = new Set(events.map(event => event.category).filter(Boolean));
    return ['all', ...Array.from(uniqueCategories)];
  }, [events]);

  const getImages = (event) => {
    const images = [];
    if (event.imageUrl) images.push(event.imageUrl);
    if (event.SharedPostThumbnail) images.push(event.SharedPostThumbnail);
    return images;
  };

  const filteredEvents = useMemo(() => {
    return events.filter(event => {
      const now = moment();
      const startTime = moment(`${event.startDate} ${event.startTime}`, 'YYYY-MM-DD HH:mm:ss');
      const endTime = moment(`${event.endDate} ${event.endTime}`, 'YYYY-MM-DD HH:mm:ss');

      let timeMatch = true;
      if (timeFilter === 'now') {
        timeMatch = now.isBetween(startTime, endTime);
      } else if (timeFilter === 'today') {
        timeMatch = startTime.isSame(now, 'day');
      } else if (timeFilter === 'upcoming') {
        timeMatch = startTime.isAfter(now);
      }

      const categoryMatch = categoryFilter === 'all' || event.category === categoryFilter;

      const searchMatch = 
        event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.venue.toLowerCase().includes(searchQuery.toLowerCase());

      return timeMatch && categoryMatch && searchMatch;
    });
  }, [events, timeFilter, categoryFilter, searchQuery]);

  const handlePrevEvent = (venue, e) => {
    e.stopPropagation();
    setVenueIndices(prev => {
      const venueGroup = filteredEvents.filter(e => e.venue === venue);
      const newIndex = (((prev[venue] || 0) - 1) + venueGroup.length) % venueGroup.length;
      return { ...prev, [venue]: newIndex };
    });
  };

  const handleNextEvent = (venue, e) => {
    e.stopPropagation();
    setVenueIndices(prev => {
      const venueGroup = filteredEvents.filter(e => e.venue === venue);
      const newIndex = ((prev[venue] || 0) + 1) % venueGroup.length;
      return { ...prev, [venue]: newIndex };
    });
  };

  const groupedEvents = filteredEvents.reduce((acc, event) => {
    if (!acc[event.venue]) acc[event.venue] = [];
    acc[event.venue].push(event);
    return acc;
  }, {});

  const formatDate = (date, time) => {
    if (!date || date === 'Invalid date') return 'Date TBA';
    const eventDate = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');
    if (!eventDate.isValid()) return 'Date TBA';
    return eventDate.format('MMM D, YYYY [at] h:mm A');
  };

  return (
    <Box sx={{ height: 'calc(100vh - 112px)', overflowX: 'hidden', overflowY: 'auto', paddingTop: 8}}>
      <FilterContainer>
        <TextField
          fullWidth
          label="Search events or venues"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 1 }}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Time</InputLabel>
            <Select
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              label="Time"
            >
              <MenuItem value="all">All Times</MenuItem>
              <MenuItem value="now">Happening Now</MenuItem>
              <MenuItem value="today">Happening Today</MenuItem>
              <MenuItem value="upcoming">Upcoming</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
              label="Category"
            >
              {categories.map(category => (
                <MenuItem key={category} value={category}>
                  {category === 'all' ? 'All Categories' : category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </FilterContainer>

      {Object.entries(groupedEvents).map(([venue, venueEvents], index) => {
        const currentIndex = venueIndices[venue] || 0;
        const currentEvent = venueEvents[currentIndex];
        const eventImages = getImages(currentEvent);

        return (
          <React.Fragment key={venue}>
            <EventCard elevation={3} onClick={() => onEventClick(currentEvent)}>
              <Typography variant="h6" align="center" sx={{ p: 1, fontWeight: 'bold' }}>{venue}</Typography>
              <Typography variant="body2" align="center" sx={{ px: 1, pb: 1, color: 'text.secondary' }}>{currentEvent.address}</Typography>
              <ImageCarousel images={eventImages} />
              <ContentBox>
                <ChipContainer>
                  <Chip 
                    label={currentEvent.ticketPrice || 'Free'} 
                    color={currentEvent.ticketPrice ? 'primary' : 'success'}
                    size="small"
                  />
                  <Chip 
                    label={moment(currentEvent.startDate).isSame(moment(), 'day') ? 'Today' : moment(currentEvent.startDate).format('MMM D')}
                    color={moment(currentEvent.startDate).isSame(moment(), 'day') ? 'warning' : 'default'}
                    size="small"
                  />
                </ChipContainer>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>{currentEvent.title}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  {formatDate(currentEvent.startDate, currentEvent.startTime)}
                </Typography>
                <Typography variant="body2">{currentEvent.description}</Typography>
              </ContentBox>
              <NavigationContainer>
                <IconButton onClick={(e) => handlePrevEvent(venue, e)} disabled={venueEvents.length === 1}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="body2" color="text.secondary">
                  {`${currentIndex + 1} of ${venueEvents.length}`}
                </Typography>
                <IconButton onClick={(e) => handleNextEvent(venue, e)} disabled={venueEvents.length === 1}>
                  <ChevronRight />
                </IconButton>
              </NavigationContainer>
            </EventCard>
            
            {/* Insert AdSense ad after every 2 events */}
            {(index + 1) % 2 === 0 && (
              <Box sx={{ my: 2 }}>
                <AdComponent />
              </Box>
            )}
          </React.Fragment>
        );
      })}

      {filteredEvents.length === 0 && (
        <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
          No {viewType} found matching your criteria.
        </Typography>
      )}
    </Box>
  );
};

export default ListView;